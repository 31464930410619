import { Magic } from 'magic-sdk';
import {useEffect, useState} from "react";

export const magic = new Magic('pk_live_D81BD4EF78E66FA2', {
    network:  {
        rpcUrl: 'https://polygon-rpc.com/', // Polygon RPC URL
        chainId: 137, // Polygon chain id
    }
});

export function useMagic() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    function pollAccount() {
        magic.user.isLoggedIn().then(isLoggedIn => {
            setIsLoggedIn(isLoggedIn)
            if(isLoggedIn) {
                magic.user.getMetadata().then(user => {
                    setUser(user);
                    setLoading(false);
                });
            }
            else {
                setUser({ user: null });
                setLoading(false);
            }
        });
    }

    function signOut() {
        magic.user.logout().then(() => {
            setUser(null);
            setIsLoggedIn(false);
        });
    }

    async function signIn(email) {
        // console.log("SIGNINGIN", email)
        const result = await magic.auth.loginWithEmailOTP({email, showUI: true});
        if(result) {
            setUser(await magic.user.getMetadata());
            setIsLoggedIn(true);
        }
    }

    useEffect(() => {
        const handle = setInterval(() => {
            // pollAccount();
        }, 5000);

        pollAccount();

        return () => clearInterval(handle);
    }, []);

    return [user, loading, isLoggedIn, signIn, signOut];

}