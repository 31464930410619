import {useEffect, useState} from 'react';
import {magic, useMagic} from "./magic";
import {Box, Button, CircularProgress, Input} from "@mui/material";

// import ethers6
import { ethers } from 'ethers';

import { Network, Alchemy } from 'alchemy-sdk';
//gV7uWATBc9K6FfuJpBw8-_BduOrcU_pS

// Optional Config object, but defaults to demo api-key and eth-mainnet.
const settings = {
    apiKey: 'gV7uWATBc9K6FfuJpBw8-_BduOrcU_pS', // Replace with your Alchemy API Key.
    network: Network.MATIC_MAINNET, // Replace with your network.
};

const alchemy = new Alchemy(settings);


function EmailSignUpForm({signIn}) {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        signIn(email);
        e.preventDefault();
    }

    return (
        <form onSubmit={handleSubmit}>
            <p>Enter your email to connect to your SOMNIA account</p>
            <div style={{display: 'flex', alignItems: 'stretch'}}>
                <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{flex: 1, alignSelf: 'stretch', marginRight: '1rem'}}
                />
                <Button
                    ml={3}
                    variant="outlined"
                    onClick={handleSubmit} >
                    Login
                </Button>
            </div>
        </form>
    );
}

function App() {
    return (
        <Box src={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            backgroundColor: 'black',
            color: 'white',
        }}>
            <header className="App-header">
                <img src={"somnia.webp"} width={"30%"}/>
                <AppInner/>
            </header>
        </Box>
    );
}

function AppInner() {
    const [user, loading, isLoggedIn, signIn, signOut] = useMagic();

    if(loading) return <p>Loading...</p>
    if(!isLoggedIn) return <EmailSignUpForm signIn={signIn}/>

    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');

    if(action) {
        return <Intent self={user.publicAddress}/>
    }

    return (
        <div>
            <p>Email: {user.email}</p>
            <p>Address: {user.publicAddress}</p>
            <Button variant={"outlined"} onClick={signOut}>Logout</Button>
        </div>
    );
}

function Intent({self}) {
    // parse intent from query params
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');

    if(action === 'send') {
        return <SendIntent self={self}/>
    }
}



function SendIntent({self}) {
    const urlParams = new URLSearchParams(window.location.search);
    const to = urlParams.get('to');
    const chainId = urlParams.get('chainId');
    const contract = urlParams.get('contract');
    const tokenId = urlParams.get('tokenId');

    const [nfts, setNfts] = useState([]);
    const [toName, setToName] = useState('');

    const [sent, setSent] = useState("");
    const [sending, setSending] = useState(false);

    async function sendNft() {
        setSending(true);
        const provider = new ethers.BrowserProvider(magic.rpcProvider);
        const signer = await provider.getSigner();
        const erc721 = new ethers.Contract(contract, ['function safeTransferFrom(address from, address to, uint256 tokenId)'], signer);
        await erc721.safeTransferFrom(self, to, tokenId);
        setSending(false);
        setSent("sent");
    }

    useEffect(() => {
        alchemy.nft.getNftsForOwner(self).then(result => setNfts(result.ownedNfts));
        fetch("https://service.som1.io/entities/" + to).then(res => res.json()).then(json => {
            setToName(json.name)
        });
    }, [])


    const nftToSend = nfts.find(nft => nft.contract.address.toLowerCase() === contract.toLowerCase() && nft.tokenId === tokenId);

    if(!nftToSend) return <p>You don't own this NFT</p>

    const image = nftToSend.image.pngUrl;
    const contractName = nftToSend.contract.name;

    if(sending) {
        return <div>
            <p>Sending...</p>
            <CircularProgress/>.
        </div>
    }

    if(sent !== "") {
        return <p>Sent!</p>
    }

    return <div>
        <h1>Send to {toName}.som</h1>
        {/*<p>{JSON.stringify(nfts, null, 2)}</p>*/}
        <img src={image} style={{borderRadius: '20px', width: '200px'}}/>
        <h3>{contractName} #{tokenId}</h3>
        <Button onClick={sendNft} variant={"outlined"}>Confirm</Button>
    </div>
}

export default App;
